<template>
  <div
    id="produits"
    class="container"
  >
    <h2>{{ $t('suggestions.title') }}</h2>
    <div class="produit-liste row">
      <div v-for="product in products" :key="product.id" class="col-12 col-md-6 col-lg-3 produit">
        <ProduitItem
          hide-title
          :shop-id="shop.id"
          :product-id="product.id"
          :offer="product.reseller_offers && product.reseller_offers[0]"
          :product="product"
          :image="product.images.raw.url"
          :favourite.sync="product.favourite"
        />
      </div>
    </div>
    <div class="produit-bg d-none d-md-flex" />
  </div>
</template>

<script>
import ProduitItem from '@/components/Produits/ProduitItem.vue'
export default {
  name: 'Produits',
  components: {
    ProduitItem
  },
  props: {
    productId: { type: String }
  },
  data: () => ({
    loading: false,
    limit: 8,
    products: []
  }),
  computed: {
    shop() {
      return this.$store.getters['shop/current']
    }
  },
  watch: {
    productId: 'load'
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      return this.$store.dispatch('product/suggestions', { shopId: this.shop.id, id: this.productId, limit: this.limit })
        .then(products => (this.products = products))
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

#produits {
  position: relative;
  margin-top: 60px;
  margin-bottom: 150px;

  @include media-breakpoint-up(md) {
    margin-bottom: 250px;
  }
}
.produit-liste {
  position: relative;
  z-index: 2;

  .produit {
    margin-bottom: 20px;
  }
}
.produit-bg {
  position: absolute;
  top: 60px;
  left: 18%;
  right: 18%;
  bottom: -60px;
  border: 15px solid var(--red-color);
  z-index: 1;
  @include media-breakpoint-up(lg) {
   left: 10%;
    right: 10%;
  }
}
h2 {
  z-index: 2;
  display: inline-block;
  background-color: white;
  padding: 0 20px;
  position: relative;
  font-size: 30px;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 20px;
  @include media-breakpoint-up(md) {
    font-size: 35px;
    margin-bottom: 40px;
    margin-top: 43px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 40px;
  }
}
.voir-tous {
  margin-top: 20px;
  display: inline-block;
  margin-right: 0;
  margin-left: auto;
  text-align: right;
  font-size: 25px;
  font-weight: 700;
  color: var(--gray-color);
  background-color: white;
  position: relative;
  z-index: 2;
  text-decoration: none;
  padding: 0 20px;

  @include media-breakpoint-up(lg) {
    margin-top: 70px;
  }
}
</style>
