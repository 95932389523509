<template>
  <div id="produit-content">
    <Breadcrumb :product="product && product.getTitle($l)" :category-id="product && product.youpaq.category" />
    <div v-if="product" class="produit-content-container row">
      <div class="col-12 col-md-4">
        <div class="produit-image d-none d-md-flex">
          <img :src="image ? $resize(image, { w: 500, h: 500, f: 'contain' }) : '@/assets/photo-produit.png'" />
        </div>
      </div>
      <div class="col-12 col-md-8">
        <div class="produit-details">
          <h1><span v-if="product.brand">{{ product.brand }} </span>{{ product.getTitle($l) }}</h1>
          <p>{{ product | packaging }}</p>
          <div class="produit-image d-md-none">
            <img :src="image ? $resize(image, { w: 500, h: 500, f: 'contain' }) : '@/assets/photo-produit.png'" />
          </div>
          <div v-if="product.description && product.getDescription($l)" v-marked="product.getDescription($l)"></div>
          <a
            href="#produit-details"
            class="voir-plus"
          >{{ $t('article.more') }}</a>

          <form
            class="produit-addtocart mt-3 mb-5"
            @submit.stop.prevent="submit"
          >
            <CartAjoutProduit
              v-if="product"
              ref="adder"
              dense
              :article="product"
              :quantity.sync="quantity"
              :selected-quantity.sync="selectedQuantity"
              :portions.sync="portions"
              :choose-type.sync="chooseType"
              :valid.sync="valid"
            />

            <div class="produit-price mt-3" :class="{ promo: isPricePromotion }">
              <span class="normal-price">{{ offer.getPrice(quantity, true) | price }}</span>
              <span v-if="isPricePromotion" class="promo-price">{{ offer.getPrice(quantity) | price }}</span>
            </div>

            <button
              type="submit"
              name="produit-submit"
              class="produit-submit"
              :disabled="loading || !valid"
            >
              {{ $t('article.buy') }}
            </button>
          </form>
          <div class="produit-favoris">
            <button @click="toggleFavourite">
              <img v-show="!produitFavoris" src="@/assets/produit-favoris-empty.svg" />
              <img v-show="produitFavoris" src="@/assets/produit-favoris-full.svg" />
            </button>
            <span @click="toggleFavourite">{{ $t('article.addFavourite') }}</span>
          </div>
          <div class="retour-commercant d-flex align-items-center">
            <router-link :to="{ name: 'shop' }">
              <img src="@/assets/produit-retour.svg">
              <span>{{ $t('article.back') }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/UI/Breadcrumb.vue'
import CartAjoutProduit from '@/components/CartCheckout/CartAjoutProduit'

export default {
  name: 'ProduitContent',
  components: {
    Breadcrumb,
    CartAjoutProduit
  },
  props: {
    productId: { type: String }
  },
  data: () => ({
    loading: false,
    product: null,
    produitFavoris: false,
    valid: false,
    chooseType: 'unit',
    quantity: null,
    selectedQuantity: null,
    portions: null
  }),
  computed: {
    partner() {
      return this.$store.getters['partner/current']
    },
    shop() {
      return this.$store.getters['shop/current']
    },
    offer() {
      return this.product.reseller_offers[0]
    },
    image() {
      return this.product?._links?.images?.raw?.href
    },
    promotion() {
      return this.offer?.promotion
    },
    isPricePromotion() {
      return this.promotion && this.promotion.price !== this.offer.getBaseRawPrice()
    }
  },
  watch: {
    productId: 'load'
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      return this.$store.dispatch('product/get', { shopId: this.shop.id, id: this.productId })
        .then(product => {
          this.product = product
          this.produitFavoris = this.product.favourite
          this.$nextTick(() => this.$refs.adder.init())
        })
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    },

    toggleFavourite() {
      if (this.loading) {
        return
      }
      this.loading = true
      return this.$store.dispatch('user/productFavouriteToggle', { shopId: this.shop.id, productId: this.productId })
        .then(active => {
          this.produitFavoris = active
        })
        .catch(err => {
          if (err?.response?.status === 401) {
            this.$store.commit('modal/show', {
              component: 'LoginPopup',
              params: {
                returnPath: `${this.$route.path}#shops`
              }
            })
            return
          }
          this.$err(err)
        })
        .finally(() => (this.loading = false))
    },

    submit() {
      if (this.loading || !this.valid) {
        return
      }
      this.loading = true
      return this.$refs.adder
        .submit(this.shop.id, this.offer.id)
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

/* Overwritten Carousel 3d style */
#produit-content {
  margin: auto;
  padding: 40px 20px;

  @include media-breakpoint-up(md) {
    max-width: 90%;
    padding: 40px 30px 100px;
  }
}
.produit-content-container {
  display: flex;
  margin-top: 50px;

  @include media-breakpoint-up(md) {
    margin-top: 40px;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 90px;
  }
}
.produit-image {
  padding-right: 5%;
  box-sizing: border-box;
  padding-bottom: 20px;
}
.produit-image img {
  width: 100%;
  border: 10px solid white;
  box-shadow: 0px 1px 7px #0000005e;
}
.produit-details {
  text-align: center;

  @include media-breakpoint-up(md) {
    text-align: left;
  }
}
.produit-details h1 {
  font-size: 30px;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 20px;
  text-align: left;

  @include media-breakpoint-up(md) {
    font-size: 40px;
  }
}
.produit-details p {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 10px;
  line-height: 1.8rem;
  margin-top: 20px;

  @include media-breakpoint-up(md) {
    font-size: 18px;
    margin-top: 0;
  }
}
.produit-details a.voir-plus {
  font-weight: 700;
  font-size: 16px;
  color: var(--purple-color);
  text-decoration: none;
  text-align: left;
  display: block;
  padding-bottom: 20px;

  &:hover {
    text-decoration: underline;
  }
}
.produit-quantity {
  font-size: 18px;
}
.produit-price {
  font-size: 30px;
  font-weight: 700;
  padding-bottom: 10px;
  &.promo .normal-price {
    text-decoration: line-through;
    color: #E45E43;
  }
  .promo-price {
    padding-left: 20px;
  }

}
.produit-addtocart {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  z-index: 2;
  position: relative;
  color: black;

  @include media-breakpoint-up(md) {
    align-items: flex-start;
  }
}
input.produit-bouteilles {
  font-weight: 300;
  color: #3E3E3D;
  padding: 15px;
  width: 100px;
  border-radius: 10px;
  border: none;
  box-shadow: inset 1px 1px 3px #000000;
  height: 40px;
  font-size: 18px;
  font-family: inherit;
  margin-right: 10px;

  &:focus-visible {
    outline: transparent;
  }
}
.produit-submit {
  background-color: var(--purple-color);
  border: none;
  border-radius: 10px;
  padding: 10px 25px;
  color: white;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  box-shadow: 1px 1px 4px #00000069;
}
.produit-favoris {
  display: flex;
  align-items: center;
  margin-top: 40px;
}
.produit-favoris img {
  margin-right: 10px;
  max-width: 50px;
}
.produit-favoris span, .retour-commercant span {
  font-weight: 700;
  font-size: 18px;
}
.produit-favoris button {
  border: none;
  background: transparent;
  padding: 0;
}
.retour-commercant {
  margin-top: 15px;
}
.retour-commercant a {
  display: flex;
  text-align: left;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  color: var(--gray-color);
}
.retour-commercant img {
  max-width: 50px;
  margin-right: 10px;
}
</style>
