<template>
  <div id="produit-details">
    <BigText
      color="white"
      bgcolor="purple"
      :title="$t('article.more1')"
      position="right"
    />
    <div class="text-bg">
      <BigText
        color="white"
        bgcolor="purple"
        :title="$t('article.more2')"
        position="right"
      />
    </div>
    <div v-if="product" class="produit-tabs row">
      <div
        v-if="product.composition && product.getComposition($l)"
        class="produit-tab col-12"
        :class="{ active: tabComposition }"
        @click="tabComposition = !tabComposition"
      >
        <div class="produit-tab-title">
          {{ $t('article.description') }}
          <img src="@/assets/right-arrow-white.svg">
        </div>
        <div v-marked="product.getComposition($l)" class="produit-tab-content"></div>
      </div>

      <div
        v-if="productRegionalData"
        class="produit-tab col-12"
        :class="{ active: tabRegional }"
        @click="tabRegional = !tabRegional"
      >
        <div class="produit-tab-title">
          {{ $t('article.region') }}
          <img src="@/assets/right-arrow-white.svg">
        </div>
        <div class="produit-tab-content">
          {{ productRegionalData.join(' / ') }}
        </div>
      </div>

      <div
        v-if="product.specific && product.specific.vintage && product.specific.vintage.length"
        class="produit-tab col-12"
        :class="{ active: tabMillesime }"
        @click="tabMillesime = !tabMillesime"
      >
        <div class="produit-tab-title">
          {{ $t('article.vintage') }}
          <img src="@/assets/right-arrow-white.svg">
        </div>
        <div class="produit-tab-content">
          {{ product.specific.vintage.join(', ') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BigText from '@/components/UI/BigText.vue'

export default {
  name: 'ProduitDetails',
  components: {
    BigText
  },
  props: {
    productId: { type: String }
  },
  data: () => ({
    loading: false,
    product: null,
    tabComposition: false,
    tabRegional: false,
    tabMillesime: false
  }),
  computed: {
    shop() {
      return this.$store.getters['shop/current']
    },
    productRegionalData() {
      const data = []
      if (this.product.producer) {
        data.push(this.product.producer)
      }
      if (this.product.region) {
        data.push(this.product.region)
      }
      if (this.product.made_in) {
        data.push(this.product.made_in)
      }
      return data.length ? data : null
    }
  },
  watch: {
    productId: 'load'
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      return this.$store.dispatch('product/get', { shopId: this.shop.id, id: this.productId })
        .then(product => (this.product = product))
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

.text-bg h2{
  top:50px;
  @include media-breakpoint-up(md) {
    top: 90px;
  }
  @include media-breakpoint-up(lg) {
    top: 120px;
  }
}
#produit-details {
  position: relative;
  background-color: var(--purple-color);
  text-align: left;
  padding: 80px 5% 20px 5%;

  @include media-breakpoint-up(md) {
    padding: 60px 5% 30px 5%;
  }

  @include media-breakpoint-up(lg) {
    padding: 120px 5% 110px 5%;
  }
}
.produit-tab {
  margin-bottom: 40px;
  cursor: pointer;
  z-index: 2;
  position: relative;
}
.produit-tab-title {
  font-size: 30px;
  color: white;
  font-weight: 900;
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    font-size: 27px;
  }
}
.produit-tab-title img{
  max-width: 20px;
  margin-left: 20px;

  @include media-breakpoint-up(md) {
    max-width: 25px;
  }
}
.produit-tab-content {
  font-size: 18px;
  line-height:1.8rem;
  font-weight: 400;
  color: white;
  margin-top: 30px;
  margin-left: 5%;
  display: none;
  @include media-breakpoint-up(lg) {
    margin-top: 30px;
    margin-left: 10%;
  }
  @include media-breakpoint-up(lg) {
    margin-top: 70px;
    margin-left: 10%;
    margin-bottom: 70px;
  }
}

.produit-tab.active .produit-tab-content {
  display: block;
}

.produit-tab.active .produit-tab-title img {
  transform: rotate(90deg);
}
</style>
