<template>
  <div>
    <CommercantHeader />
    <ProduitContent :product-id="productId" />
    <ProduitDetails :product-id="productId" />
    <ProduitsApparentes :product-id="productId" />
  </div>
</template>

<script>
import CommercantHeader from '@/components/Commercant/CommercantHeader'
import ProduitContent from '@/components/Produits/ProduitContent.vue'
import ProduitDetails from '@/components/Produits/ProduitDetails.vue'
import ProduitsApparentes from '@/components/Produits/ProduitsApparentes.vue'

export default {
  name: 'ViewProduit',
  components: {
    CommercantHeader,
    ProduitContent,
    ProduitDetails,
    ProduitsApparentes
  },
  props: {
    productId: { type: String }
  }
}
</script>

<style lang="scss">
@import '../assets/styles/youpaq.scss';

#produits {
  margin: auto;
  width: 90%;
  max-width: 1300px;
}
/* Commercant Header slider */
#commercant-header .agile .agile__nav-button {
  background: transparent !important;
  border: none;
  position: absolute;
  bottom: 200px;
}
#commercant-header .agile__actions {
  position: absolute;
  width: 100%;
}
#commercant-header .agile__nav-button--prev {
  left: 50px;
}
#commercant-header .agile__nav-button--next {
  right: 50px;
}
#commercant-header .agile__dots {
  position: absolute;
  bottom: 0px;
  left: calc(50% - 50px);
}
#commercant-header .agile__dots .agile__dot button{
  background-color: transparent;
  width: 15px !important;
  height: 15px !important;
  margin: 10px;
  background-size: contain !important;
  border: none;
  background: url("~@/assets/slider-dot-red.svg") no-repeat;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2)) !important;
  cursor: pointer;
}
#commercant-header .agile__dot--current button{
  background: url("~@/assets/slider-dot-red-current.svg") no-repeat !important;
}
</style>
